import React, { useState } from 'react'
import { CloseIcon } from '../../icons'
export const TemporaryMaintenanceBanner = () => {
  const [showBanner, setShowBanner] = useState(true)

  return showBanner ? (
    <div className="max-w-screen-sm mx-auto mt-md">
      <div className="card p-lg relative">
        <button
          aria-label="Close banner"
          onClick={() => setShowBanner(false)}
          className="btn btn-icon absolute top-md right-lg"
        >
          <CloseIcon width="1rem" height="1rem" />
        </button>
        <div className="prose">
          <h1>Bitte beachten!</h1>
          <p>Dieses Portal enthält - bis auf weiteres - ausschließlich LGA-Informationen der Tomra Systeme.</p>
          <p>
            Bei Fragen wenden Sie sich bitte an das Produktteam{' '}
            <a href="mailto:ERH@aldi-nord.de" className="link no-underline">
              ERH@aldi-nord.de
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  ) : null
}
